import React from 'react';
import styled from "styled-components";
import { FaCode, FaCog, FaUsers } from "react-icons/fa";

function Services() {
    return (
        <Section id="services">
            <Container>
                <Content>
                    <FaCode />
                    <Title>{'Web Development'}</Title>
                    <Text>{"Whether you're looking to build a website or web application from the ground up, or need support with an existing project, I'm here to assist. With a broad range of expertise in web development, I offer solutions that span from utilizing content management systems and open-source tools to creating fully customized, bespoke applications tailored to your unique needs."}</Text>
                </Content>

                <Content>
                    <FaCog />
                    <Title>{'Site Maintenance'}</Title>
                    <Text>{"Let me take care of your website updates quickly and consistently, keeping your site current and fully functional. I offer ongoing, comprehensive web support to ensure your platform remains stable, secure, and always accessible. With this proactive approach, your online storefront stays open for business, helping you maintain a dynamic and engaging online presence."}</Text>
                </Content>

                <Content>
                    <FaUsers />
                    <Title>{'Web Consulting'}</Title>
                    <Text>{"Need help with your project? I'm ready to assist with strategizing, designing, and developing your product, whether you're starting from scratch or need support at any stage of your web strategy. Let's connect! I'm excited to discuss potential projects or answer any questions you may have. I'm here to offer the support you need."}</Text>
                </Content>
            </Container>
        </Section>
    );
}

export default Services;

const Section = styled.section`
    padding: 100px 0;
    width: 100%;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1260px;
    padding: 0 20px;

    @media (min-width: 768px) {
        align-items: center;
        display: flex;
    }
`;

const Content = styled.div`
    padding: 20px;
    text-align: center;

    svg {
        color: #31b0d5;
        height: 80px;
        width: 80px;
    }
`;

const Title = styled.h2`
    color: #fcad26;
    display: block;
    font-size: 30px;
    font-weight: normal;
`;

const Text = styled.p`
    line-height: 30px;
`;
